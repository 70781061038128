import React from "react";

import PiwikPro from "@piwikpro/react-piwik-pro";
import { Provider } from "react-redux";

import Store from "./src/state/Store";

import "./src/styles/global.scss";

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => {
  const store = Store();

  return (
    <Provider store={store}>
      {element}
    </Provider>
  );
};

export const onClientEntry = () => {
  if (process.env.GATSBY_PIWIK_SITE_ID) {
    PiwikPro.initialize(process.env.GATSBY_PIWIK_SITE_ID, "https://kunstmuseum-gelsenkirchen.containers.piwik.pro");
  }
};

// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload();
