import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: {},
};

const identitySlice = createSlice({
  initialState,
  name: "identity",
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  setIsAuthenticated,
  setUser,
} = identitySlice.actions;

export default identitySlice.reducer;
