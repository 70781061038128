import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {},
  searchTerm: "",
};

const ArtworkFilterSlice = createSlice({
  initialState,
  name: "artworkFilter",
  reducers: {
    addFilter: (state, { payload: { section, value } }) => {
      if (typeof state.filters[section] == "undefined") {
        state.filters[section] = [];
      }

      if (!state.filters[section].includes(value)) {
        state.filters[section].push(value);
      }
    },
    removeFilter: (state, { payload: { section, value } }) => {
      state.filters[section] = state.filters[section].filter((item) => item !== value);
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
});

export const {
  addFilter,
  removeFilter,
  setSearchTerm,
} = ArtworkFilterSlice.actions;

export default ArtworkFilterSlice.reducer;
