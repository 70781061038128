import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {},
};

const ProjectFilterSlice = createSlice({
  initialState,
  name: "projectFilter",
  reducers: {
    addFilter: (state, { payload: { section, value } }) => {
      if (typeof state.filters[section] == "undefined") {
        state.filters[section] = [];
      }

      if (!state.filters[section].includes(value)) {
        state.filters[section].push(value);
      }
    },
    removeFilter: (state, { payload: { section, value } }) => {
      state.filters[section] = state.filters[section].filter((item) => item !== value);
    },
  },
});

export const {
  addFilter,
  removeFilter,
} = ProjectFilterSlice.actions;

export default ProjectFilterSlice.reducer;
