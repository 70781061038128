import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {},
  filtersReview: {},
  searchTerm: "",
};

const ExhibitionFilterSlice = createSlice({
  initialState,
  name: "exhibitionFilter",
  reducers: {
    addFilter: (state, { payload: { section, value } }) => {
      if (typeof state.filters[section] == "undefined") {
        state.filters[section] = [];
      }

      if (!state.filters[section].includes(value)) {
        state.filters[section].push(value);
      }
    },
    removeFilter: (state, { payload: { section, value } }) => {
      state.filters[section] = state.filters[section].filter((item) => item !== value);
    },
    // eslint-disable-next-line sort-keys
    addReviewFilter: (state, { payload: { section, value } }) => {
      if (typeof state.filtersReview[section] == "undefined") {
        state.filtersReview[section] = [];
      }

      if (!state.filtersReview[section].includes(value)) {
        state.filtersReview[section].push(value);
      }
    },
    removeReviewFilter: (state, { payload: { section, value } }) => {
      state.filtersReview[section] = state.filtersReview[section].filter((item) => item !== value);
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
});

export const {
  addFilter,
  addReviewFilter,
  removeFilter,
  removeReviewFilter,
  setSearchTerm,
} = ExhibitionFilterSlice.actions;

export default ExhibitionFilterSlice.reducer;
