module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"de-DE","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"redirect":true},"languages":["de-DE","en-GB"],"localeJsonSourceName":"locale","pages":[{"getLanguageFromPath":true,"matchPath":["/:lang?","/:lang?/(.*)"]}],"siteUrl":"https://www.kunstmuseum-gelsenkirchen.de","trailingSlash":"always"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"allPageHeaders":[],"generateMatchPathRewrites":false,"headers":{},"mergeCachingHeaders":false,"mergeLinkHeaders":true,"mergeSecurityHeaders":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#ffffff","display":"standalone","icon":"src/images/favicons/favicon.png","name":"Kunstmuseum Gelsenkirchen","start_url":"/","theme_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0ec469f2d10a3a01a9cf08dee2c6529a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
