exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-missingtranslation-jsx": () => import("./../../../src/pages/missingtranslation.jsx" /* webpackChunkName: "component---src-pages-missingtranslation-jsx" */),
  "component---src-templates-pages-artwork-page-jsx": () => import("./../../../src/templates/pages/ArtworkPage.jsx" /* webpackChunkName: "component---src-templates-pages-artwork-page-jsx" */),
  "component---src-templates-pages-collection-overview-page-jsx": () => import("./../../../src/templates/pages/CollectionOverviewPage.jsx" /* webpackChunkName: "component---src-templates-pages-collection-overview-page-jsx" */),
  "component---src-templates-pages-digital-archive-overview-page-jsx": () => import("./../../../src/templates/pages/DigitalArchiveOverviewPage.jsx" /* webpackChunkName: "component---src-templates-pages-digital-archive-overview-page-jsx" */),
  "component---src-templates-pages-digital-archive-page-jsx": () => import("./../../../src/templates/pages/DigitalArchivePage.jsx" /* webpackChunkName: "component---src-templates-pages-digital-archive-page-jsx" */),
  "component---src-templates-pages-exhibition-overview-page-jsx": () => import("./../../../src/templates/pages/ExhibitionOverviewPage.jsx" /* webpackChunkName: "component---src-templates-pages-exhibition-overview-page-jsx" */),
  "component---src-templates-pages-exhibition-page-jsx": () => import("./../../../src/templates/pages/ExhibitionPage.jsx" /* webpackChunkName: "component---src-templates-pages-exhibition-page-jsx" */),
  "component---src-templates-pages-index-jsx": () => import("./../../../src/templates/pages/Index.jsx" /* webpackChunkName: "component---src-templates-pages-index-jsx" */),
  "component---src-templates-pages-legal-page-jsx": () => import("./../../../src/templates/pages/LegalPage.jsx" /* webpackChunkName: "component---src-templates-pages-legal-page-jsx" */),
  "component---src-templates-pages-museum-page-jsx": () => import("./../../../src/templates/pages/MuseumPage.jsx" /* webpackChunkName: "component---src-templates-pages-museum-page-jsx" */),
  "component---src-templates-pages-paper-chase-overview-page-jsx": () => import("./../../../src/templates/pages/PaperChaseOverviewPage.jsx" /* webpackChunkName: "component---src-templates-pages-paper-chase-overview-page-jsx" */),
  "component---src-templates-pages-paper-chase-start-page-jsx": () => import("./../../../src/templates/pages/PaperChaseStartPage.jsx" /* webpackChunkName: "component---src-templates-pages-paper-chase-start-page-jsx" */),
  "component---src-templates-pages-program-page-jsx": () => import("./../../../src/templates/pages/ProgramPage.jsx" /* webpackChunkName: "component---src-templates-pages-program-page-jsx" */),
  "component---src-templates-pages-programs-overview-page-jsx": () => import("./../../../src/templates/pages/ProgramsOverviewPage.jsx" /* webpackChunkName: "component---src-templates-pages-programs-overview-page-jsx" */),
  "component---src-templates-pages-project-page-jsx": () => import("./../../../src/templates/pages/ProjectPage.jsx" /* webpackChunkName: "component---src-templates-pages-project-page-jsx" */),
  "component---src-templates-pages-publication-page-jsx": () => import("./../../../src/templates/pages/PublicationPage.jsx" /* webpackChunkName: "component---src-templates-pages-publication-page-jsx" */),
  "component---src-templates-pages-research-and-projects-overview-page-jsx": () => import("./../../../src/templates/pages/ResearchAndProjectsOverviewPage.jsx" /* webpackChunkName: "component---src-templates-pages-research-and-projects-overview-page-jsx" */),
  "component---src-templates-pages-research-page-jsx": () => import("./../../../src/templates/pages/ResearchPage.jsx" /* webpackChunkName: "component---src-templates-pages-research-page-jsx" */),
  "component---src-templates-pages-simple-rich-text-page-jsx": () => import("./../../../src/templates/pages/SimpleRichTextPage.jsx" /* webpackChunkName: "component---src-templates-pages-simple-rich-text-page-jsx" */),
  "component---src-templates-pages-user-collection-page-jsx": () => import("./../../../src/templates/pages/UserCollectionPage.jsx" /* webpackChunkName: "component---src-templates-pages-user-collection-page-jsx" */),
  "component---src-templates-pages-user-collection-share-page-jsx": () => import("./../../../src/templates/pages/UserCollectionSharePage.jsx" /* webpackChunkName: "component---src-templates-pages-user-collection-share-page-jsx" */)
}

