import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const getUserToken = () => {
  const localUser = JSON.parse(localStorage.getItem("gotrue.user"));

  return localUser.token.access_token;
};

const axiosBaseQuery = async (args) => {
  const {
    url,
    method = "get",
    data = {},
    params = {},
    headers = {},
    blob = false,
  } = args;

  try {
    return await axios({
      data,
      headers,
      method,
      params,
      url,

      ...(blob ? {
        responseType: "blob",
      } : {}),
    });
  } catch (axiosError) {
    const err = axiosError;

    return {
      error: {
        data: err.response?.data || err.message,
        status: err.response?.status,
      },
    };
  }
};

const baseQuery = async (
  args,
  api,
  extraOptions,
) => {
  const userToken = args.apiRequirements?.includes("token")
    ? getUserToken()
    : null;

  const result = await axiosBaseQuery(
    {
      ...args,
      ...(userToken !== null && { headers: { Authorization: `Bearer ${userToken}` } }),
    },
    api,
    extraOptions,
  );

  if (result.error) {
    switch (result.error.status) {
      case 401:
        break;

      case 403:
        break;

      default:
    }
  }

  return result;
};

export default createApi({
  baseQuery,
  endpoints: () => ({}),
  keepUnusedDataFor: 300,
  reducerPath: "api",
  tagTypes: ["user-collection"],
});
