import { configureStore } from "@reduxjs/toolkit";

import baseApiSlice from "../features/api/baseApiSlice";
import ArtworkFilterSlice from "../features/ArtworkFilterSlice";
import ComplianceConsentSlice from "../features/complianceConsentSlice";
import ExhibitionFilterSlice from "../features/ExhibitionFilterSlice";
import IdentitySlice from "../features/IdentitySlice";
import PaperChaseSlice from "../features/PaperChaseSlice";
import ProjectFilterSlice from "../features/ProjectFilterSlice";
import ResearchAndProjectsFilterSlice from "../features/ResearchAndProjectsFilterSlice";

const Store = () => configureStore({
  middleware: (getDefaultMiddleware) => (
    // see: https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
    // see: https://github.com/reduxjs/redux-toolkit/issues/415
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat([
      baseApiSlice.middleware,
    ])
  ),
  reducer: {
    [baseApiSlice.reducerPath]: baseApiSlice.reducer,
    artworkFilter: ArtworkFilterSlice,
    complianceConsent: ComplianceConsentSlice,
    exhibitionFilter: ExhibitionFilterSlice,
    identitySlice: IdentitySlice,
    paperChaseSlice: PaperChaseSlice,
    projectFilter: ProjectFilterSlice,
    researchAndProjectsFilter: ResearchAndProjectsFilterSlice,
  },
});

export default Store;
