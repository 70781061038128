import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectsFilters: {},
  researchFilters: {},
  searchTerm: "",
};

const ResearchAndProjectsFilterSlice = createSlice({
  initialState,
  name: "researchAndProjectsFilter",
  reducers: {
    addProjectsFilter: (state, { payload: { section, value } }) => {
      if (typeof state.projectsFilters[section] == "undefined") {
        state.projectsFilters[section] = [];
      }

      if (!state.projectsFilters[section].includes(value)) {
        state.projectsFilters[section].push(value);
      }
    },
    removeProjectsFilter: (state, { payload: { section, value } }) => {
      state.projectsFilters[section] = state.projectsFilters[section]
        .filter((item) => item !== value);
    },
    // eslint-disable-next-line sort-keys
    addResearchFilter: (state, { payload: { section, value } }) => {
      if (typeof state.researchFilters[section] == "undefined") {
        state.researchFilters[section] = [];
      }

      if (!state.researchFilters[section].includes(value)) {
        state.researchFilters[section].push(value);
      }
    },
    removeResearchFilter: (state, { payload: { section, value } }) => {
      state.researchFilters[section] = state.researchFilters[section]
        .filter((item) => item !== value);
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
  },
});

export const {
  addProjectsFilter,
  removeProjectsFilter,
  addResearchFilter,
  removeResearchFilter,
} = ResearchAndProjectsFilterSlice.actions;

export default ResearchAndProjectsFilterSlice.reducer;
