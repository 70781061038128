import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  completedStations: [],
  currentStation: 0,
  difficulty: "",
  paperChase: {},
  score: 0,
  userAnswers: [],
};

const PaperChaseSlice = createSlice({
  initialState,
  name: "PaperChase",
  reducers: {
    addCompletedStation: (state, action) => {
      state.completedStations = [state.completedStations, action.payload];
    },
    addUserAnswer: (state, action) => {
      state.userAnswers[action.payload.index] = {
        foundQRCode: action.payload.foundQRCode,
        solvedQuiz: action.payload.solvedQuiz,
      };
    },
    addUserPoints: (state, action) => {
      state.score += action.payload;
    },
    setCurrentStation: (state, action) => {
      state.currentStation = action.payload;
    },
    setDifficulty: (state, action) => {
      state.difficulty = action.payload;
    },
    setPaperChase: (state, action) => {
      state.paperChase = action.payload;
    },
  },
});

export const {
  addCompletedStation,
  addUserAnswer,
  setCurrentStation,
  setDifficulty,
  addUserPoints,
  setPaperChase,
} = PaperChaseSlice.actions;

export default PaperChaseSlice.reducer;
